<div *ngIf="!next" class="choose-documents">
  <ng-container *ngIf="documents; else loading">
    <div class="choose-documents__body">
      <div class="choose-documents__header">
        <h3>Choose Documents you need</h3>
        <div class="search">
          <input [(ngModel)]="filter" placeholder="Search" type="text"/>
        </div>
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
                 typeOfApplication === AdmissionTypeEnum.SupervisorReport">
          Please select package:
        </p>
        <div *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport" class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.SupervisorReport)"
              [checked]="allSelectedSR"
              [disabled]="uncheckSRPackage"
              id="doc-select-all_SR"
              type="checkbox"/>
            <label for="doc-select-all_SR">
              <span class="document-name">Supervisory Visit Package</span>
            </label>
          </div>
        </div>
        <div
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.Admission)"
              [checked]="allSelectedAdmission"
              [disabled]="uncheckAdmissionPackage"
              id="doc-select-all_Admission"
              type="checkbox"/>
            <label for="doc-select-all_Admission">
              <span class="document-name">Start of Care Package</span>
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport">
        <p>Please select a separate Supervisory Visit document:</p>
        <ul
          [class.full]="typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list pb0">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'supervisory_report'">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="!(typeOfApplication === AdmissionTypeEnum.SupervisorReport && !withAdmission)">
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport &&
               withAdmission">
          Please select a separate Start of Care document:
        </p>
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.DischargeSummary ||
                  typeOfApplication === AdmissionTypeEnum.AdditionalDocuments ||
                  typeOfApplication === AdmissionTypeEnum.AdditionalDocumentsInt ||
                 typeOfApplication === AdmissionTypeEnum.POC">
          Please select document:
        </p>
        <ul class="choose-documents__list">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'admission' || doc.type === 'discharge' || doc.type === 'poc' ">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
    </div>
    <div *ngIf="documents?.length" class="next-btn-wrap">
      <button
        (click)="toggleStep()"
        [disabled]="!selectedDocs.length"
        class="btn violet next-btn"
        data-e2e="choose-documents-proceed">
        Proceed
      </button>
    </div>
    <ng-template #noItems>
      <div class="message">No documents found</div>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="next" class="choose-documents-action">
  <div *ngIf="!isPOC" class="default-docs">
    <div class="back-btn-wrapper">
      <span (click)="toggleStep()"><i class="icon-back-arrow"></i> Back to documents</span>
    </div>
    <ul class="choose-documents-action__list">
      <li *ngFor="let doc of selectedDocs">{{ doc.title }}</li>
    </ul>
    <div class="action-sign">
      <div class="action-sign__title">Send for sign by:</div>
    </div>
    <div class="choose-documents-action__items">
      <div class="choose-documents-action__item">
        <div class="choose-documents-action__buttons">
          <ul class="choose-documents-action__buttons-list">
            <li>
              <ng-container *appPermission="[PermissionAdmissionEnum.SendToSign]">
                <button
                  (click)="setNewFields('patient', 'actions', 'email')"
                  *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
                  [class.active]="dcwFormGroup.value['patient'].actions ==='email' && isSendDocs"
                  [disabled]="disableAllButtons"
                  class="text">
                  <i class="icon-mail"></i> Email
                </button>
              </ng-container>
            </li>
            <li>
              <ng-container *appPermission="([PermissionAdmissionEnum.SendToSign])">
                <button
                  (click)="setNewFields('patient', 'actions', 'phone')"
                  *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
                  [class.active]="dcwFormGroup.value['patient'].actions ==='phone'"
                  [disabled]="disableAllButtons"
                  class="text">
                  <i class="icon-file-text1"></i> Text
                </button>
              </ng-container>
            </li>
            <li>
              <button
                (click)="chooseAction('sign')"
                [disabled]="!created || disableAllButtons"
                data-e2e="choose-documents-admission-sign">
                <i class="icon-signature"></i> Sign
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary && isSendDocs"
         [formGroup]="dcwFormGroup?.get('patient') | formControl " class="default-docs__area">
      <div *ngIf="dcwFormGroup.value['patient'].actions ==='email'" class="default-docs__customer-email">
        <div class="input-wrapper__name">Customer email*</div>
        <input
          appMarkInvalid
          formControlName="patientEmail"
          type="email"/>
      </div>
      <div *ngIf="dcwFormGroup.value['patient'].actions ==='phone'" class="default-docs__customer-email">
        <div class="input-wrapper__name">Customer phone*</div>
        <input
          [mask]="phoneMask"
          appMarkInvalid
          appMask
          formControlName="patientPhone"
          placeholder="+1 (000) 000-0000"
          type="tel"/>
      </div>
      <div class="dcw">
        <div class="dcw__title">DCW on a visit</div>

        <ng-container [formGroup]="dcwFormGroup">
          <div *ngFor="let dcw of dcwArray.controls; let i = index" class="dcw__items">
            <div [formGroup]="dcw | formControl" class="dcw__list">
              <div [class.disabled-select]="dcw.get('disabled')?.value" class="dcw__item">
                <div class="dcw__item--title">DCW name*</div>
                <ng-select
                  #ngSelect
                  (change)="selectDCW($event, i)"
                  (search)="onSearch($event, i, 'onlyEmployee', ngSelect)"
                  [items]="(dcwsList$ | async)?.[i]"
                  [searchable]="true"
                  appMarkInvalid
                  bindLabel="name"
                  formControlName="name"
                  placeholder="Enter DCW Name">
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span [innerHTML]="item.complexName || item.name"></span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-label-tmp>
                    <span (click)="clear(i)" *ngIf="!item.disabled" class="remove-btn"></span>
                    <span [innerHTML]="item.complexName || item.name"></span>
                  </ng-template>
                </ng-select>
              </div>
              <ng-container *ngIf="dcwFormGroup.value['patient'].actions ==='email'">
                <div [class.disabled-select]="dcw.get('disabled')?.value" class="dcw__item">
                  <div class="dcw__item--title">DCW email*</div>
                  <label>
                    <input
                      appMarkInvalid
                      formControlName="email"
                      type="email"/>
                    <i (click)="setNewFields(i, 'email', '')"
                       *ngIf="dcw.get('email').value && !dcw.get('disabled')?.value" class="icon-close"></i>
                  </label>
                </div>
              </ng-container>
              <ng-container *ngIf="dcwFormGroup.value['patient'].actions === 'phone'">
                <div [class.disabled-select]="dcw.get('disabled')?.value" class="dcw__item">
                  <span class="dcw__item--title">DCW phone*</span>
                  <label>
                    <input
                      [mask]="phoneMask"
                      appMarkInvalid
                      appMask
                      formControlName="phone"
                      placeholder="+1 (000) 000-0000"
                      type="tel"/>
                    <i (click)="setNewFields(i, 'phone', '')"
                       *ngIf="dcw.get('phone').value && !dcw.get('disabled')?.value" class="icon-close"></i>
                  </label>
                </div>
              </ng-container>
            </div>
            <div *ngIf="dcw.get('uid').invalid" class="err-msg">
              {{ dcw.get('uid').getError('message') }}
            </div>
            <button
              (click)="removeDCW(i)"
              *ngIf="(i > 0 || dcwArray.length > 1) && !dcw.get('disabled')?.value"
              class="choose-form__rm">
              <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M7.75 1.75C4.29822 1.75 1.5 4.54822 1.5 8C1.5 11.4518 4.29822 14.25 7.75 14.25C11.2018 14.25 14 11.4518 14 8C14 4.54822 11.2018 1.75 7.75 1.75ZM0 8C0 3.71979 3.46979 0.25 7.75 0.25C12.0302 0.25 15.5 3.71979 15.5 8C15.5 12.2802 12.0302 15.75 7.75 15.75C3.46979 15.75 0 12.2802 0 8ZM10.2575 5.49255C10.5504 5.78544 10.5504 6.26031 10.2575 6.55321L8.81066 8.00005L10.2609 9.45026C10.5538 9.74315 10.5538 10.218 10.2609 10.5109C9.96798 10.8038 9.4931 10.8038 9.20021 10.5109L7.75 9.06071L6.2977 10.513C6.00481 10.8059 5.52993 10.8059 5.23704 10.513C4.94415 10.2201 4.94415 9.74524 5.23704 9.45234L6.68934 8.00005L5.24041 6.55112C4.94752 6.25823 4.94752 5.78335 5.24041 5.49046C5.53331 5.19757 6.00818 5.19757 6.30107 5.49046L7.75 6.93939L9.19684 5.49255C9.48973 5.19965 9.96461 5.19965 10.2575 5.49255Z"
                  fill="#DC7070"
                  fill-rule="evenodd"/>
              </svg>
              Remove DCW
            </button>
          </div>
        </ng-container>

        <div *ngIf="dcwArray.controls.length < 10" class="add-dcw">
          <button (click)="addDCW()" class="add-button">
            <i class="icon-plus"></i> Add one more DCW
          </button>
        </div>

        <div [formGroup]="haveCriminalForm" class="other">
          <div class="other__title">Does a DCW have a criminal record or a conviction?*</div>
          <div class="flex-radio-buttons">
            <div
              (click)="setFormValue(haveCriminalForm, 'have', true, true)"
              class="radio-item">
              <input
                [appBindRadio]="haveCriminalForm.controls.have"
                [targetValue]="true"
                id="other-yes"
                name="other"
                type="radio"/>
              <label for="other-yes">Yes</label>
            </div>
            <div
              (click)="setFormValue(haveCriminalForm, 'have', false)"
              [class.error]="haveCriminalForm.get('have').errors"
              class="radio-item">
              <input
                [appBindRadio]="haveCriminalForm.controls.have"
                [targetValue]="false"
                id="other-no"
                name="other"
                type="radio"/>
              <label for="other-no">No</label>
            </div>
            <div *ngIf="haveCriminalForm.get('have').errors" class="err-msg">
              {{ haveCriminalForm.get('have').getError('message') }}
            </div>
          </div>
          <div *ngIf="haveCriminalForm.controls['have'].value" class="other__list" formArrayName="caregivers">
            <div
              *ngFor="let item of caregiversArray.controls; let i = index; let first = first"
              class="other__list--item">
              <div [class.err]="!item.value || item.invalid && item.touched" class="other__list--flex">
                <div class="other__list--cell">
                  <div class="flex-row__item-name">Choose a DCW*</div>
                  <app-multiselect
                    [formControl]="item | formControl"
                    [id]="i"
                    [isSingleselect]="true"
                    [items]="chosenDCW"
                    [name]="''"
                    [noItemsText]="'No data to display'"
                    [prefix]="'car' + i"
                    [withoutCheckboxView]="true"
                    appMarkInvalid
                    bindLabel="complexName"
                    bindValue="uid">
                  </app-multiselect>
                  <span *ngIf="!item.value" class="err-message">Field is required</span>
                  <span *ngIf="item.invalid" class="err-message">{{ item.getError('message') }}</span>
                </div>
                <div *ngIf="!first" class="other__list--cell">
                  <i (click)="deleteCaregiver(i)" class="icon-close"></i>
                </div>
              </div>
            </div>
            <button (click)="addCaregiver()"
                    *ngIf="caregiversArray.controls.length < chosenDCW.length"
                    class="add-button">
              <i class="icon-plus"></i> Add DCW
            </button>
          </div>
        </div>

        <div class="default-docs__other">
          <div class="input-wrapper">
            <div class="input-wrapper__name">Other instructions</div>
            <input [formControl]="otherInstruction"
                   appMarkInvalid
                   type="text"
            />
          </div>
        </div>

        <div class="send">
          <button
            (click)="sendDocs()"
            [disabled]="!created || !dcwFormGroup.valid || isDisableSendAction || haveCriminalForm.invalid || otherInstruction.invalid"
            class="btn violet">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="errors.length" class="error-list">
    <div *ngFor="let err of errors" class="error">{{ err }}</div>
  </div>

  <div class="success" style="display: none">
    <div class="success__wrapper">
      <div class="success__header">
        <span class="icon"></span>
      </div>
      <div class="success__body">
        <span>Link successfully sent</span>
      </div>
    </div>
  </div>

  <div *ngIf="isPOC" class="poc-docs">
    <div class="back-btn-wrapper">
      <span (click)="backToAddPOCPopUp()" *ngIf="actionPOC !== ActionPOCEnum.AdditionalCG"><i
        class="icon-back-arrow"></i> Back</span>
    </div>
    <ul class="choose-documents-action__list">
      <li *ngFor="let doc of selectedDocs">{{ doc.title }}</li>
    </ul>

    <div class="form-title">Competency requirements training</div>

    <div [formGroup]="competencyRequirementsTraining" class="report-accordion__radio training">
      <div class="form-group radio-item">
        <input
          [id]="TrainingCompletedEnum.Person"
          [value]="TrainingCompletedEnum.Person"
          formControlName="trainingCompleted"
          name="trainingCompleted"
          type="radio"/>
        <label [for]="TrainingCompletedEnum.Person">
          <span
            class="status-name">Training completed in person, personal care aide signed participants care plan on</span>
        </label>
        <div
          (clickOutside)="showCalendars.trainingCompletedOn = false"
          *ngIf="competencyRequirementsTraining.value['trainingCompleted'] === TrainingCompletedEnum.Person"
          [class.showCalendar]="showCalendars.trainingCompletedOn"
          class="calendar person">
          <label>
            <input
              (flatpickrChange)="showCalendars.trainingCompletedOn=false"
              [allowInput]="true"
              [altInput]="false"
              [calendarShown]="showCalendars.trainingCompletedOn"
              [convertModelValue]="false"
              [dateFormat]="'m/d/Y'"
              [inline]="true"
              [maxDate]="today"
              [minDate]="ExtraValidationDate.min"
              appMarkInvalid
              appMask
              appResetDateField
              disableMobile="true"
              formControlName="trainingCompletedOn"
              mwlFlatpickr
              placeholder="mm/dd/yyyy"
              type="text"/>
            <i (click)="showCalendars.trainingCompletedOn = true" class="icon-calendar" style="top: 11px;"></i>
          </label>
        </div>
      </div>
      <div class="form-group radio-item">
        <input
          [id]="TrainingCompletedEnum.Phone"
          [value]="TrainingCompletedEnum.Phone"
          class="radio-item"
          formControlName="trainingCompleted"
          name="trainingCompleted"
          type="radio"/>
        <label [for]="TrainingCompletedEnum.Phone">
      <span
        class="status-name">Training completed over the phone, personal care aide verbalized the 100% understanding</span>
        </label>
      </div>
      <div class="form-group radio-item">
        <input
          [id]="'NoneTrainingCompleted'"
          [value]="TrainingCompletedEnum.None"
          class="radio-item"
          formControlName="trainingCompleted"
          name="trainingCompleted"
          type="radio"/>
        <label [for]="'NoneTrainingCompleted'">
          <span class="status-name">None</span>
        </label>
      </div>
      <div class="form-group complete">
        <span class="status-name">Training complete</span>
        <div
          (clickOutside)="showCalendars.signedDate = false"
          [class.showCalendar]="showCalendars.signedDate"
          class="calendar bottom">
          <label>
            <input
              (flatpickrChange)="showCalendars.signedDate = false"
              [allowInput]="true"
              [altInput]="false"
              [calendarShown]="showCalendars.signedDate"
              [convertModelValue]="false"
              [dateFormat]="'m/d/Y'"
              [inline]="true"
              [maxDate]="today"
              [minDate]="ExtraValidationDate.min"
              appMarkInvalid
              appMask
              appResetDateField
              disableMobile="true"
              formControlName="signedDate"
              mwlFlatpickr
              placeholder="mm/dd/yyyy"
              type="text"/>
            <i (click)="showCalendars.signedDate = true" class="icon-calendar" style="top: 11px;"></i>
          </label>
        </div>
      </div>
    </div>
    <div class="choose-documents-action__buttons pb0">
      <h3>Send for sign by:</h3>
    </div>


    <div [formGroup]="dcwFormGroup"
         class="choose-documents-action__items">
      <ng-container *ngIf="!hasSignedAdmission || actionPOC === ActionPOCEnum.ChangePOC" formGroupName="patient">
        <ul class="choose-documents-action__buttons-list">
          <li>
            <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
              <button
                (click)="setNewFields('patient', 'actions', 'email')"
                [class.active]="dcwFormGroup.value['patient'].actions ==='email'"
                [disabled]="disableAllButtons"
                class="text">
                <i class="icon-mail"></i> Email
              </button>
            </ng-container>
          </li>
          <li>
            <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
              <button
                (click)="setNewFields('patient', 'actions', 'phone')"
                [class.active]="dcwFormGroup.value['patient'].actions ==='phone'"
                [disabled]="disableAllButtons"
                class="text">
                <i class="icon-file-text1"></i> Text
              </button>
            </ng-container>
          </li>
        </ul>
        <div
          class="choose-form">
          <div class="choose__list">
            <ng-container *ngIf="dcwFormGroup.get('patient').get('actions')?.value === 'email';">
              <div class="choose__item customer">
                <span>Customer email*</span>
                <label>
                  <input
                    appMarkInvalid
                    formControlName="patientEmail"
                    type="email"/>
                  <i (click)="setNewFields('patient', 'patientEmail', '')"
                     *ngIf="dcwFormGroup.get('patient').value['patientEmail']" class="icon-close"></i>
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="dcwFormGroup.get('patient').get('actions')?.value === 'phone';">
              <div class="choose__item">
                <span>Customer phone*</span>
                <label>
                  <input
                    [mask]="phoneMask"
                    appMarkInvalid
                    appMask
                    formControlName="patientPhone"
                    placeholder="+1 (000) 000-0000"
                    type="tel"
                  />
                  <i
                    *ngIf="dcwFormGroup.get('patient').value['patientPhone']"
                    (click)="setNewFields('patient', 'patientPhone', '')"
                    class="icon-close">
                  </i>
                </label>
                <span class="err-msg"></span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="choose-documents-action__item">
        <div
          [formGroup]="dcwFormGroup.get('supervisor') | formControl" class="choose-documents-action__buttons">
          <ul class="choose-documents-action__buttons-list">
            <li>
              <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
                <button
                  (click)="setNewFields('supervisor', 'actions', 'email')"
                  [class.active]="dcwFormGroup.get('supervisor').value['actions']==='email'"
                  [disabled]="disableAllButtons"
                  class="text">
                  <i class="icon-mail"></i> Email
                </button>
              </ng-container>
            </li>
            <li>
              <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
                <button
                  (click)="setNewFields('supervisor', 'actions', 'phone')"
                  [class.active]="dcwFormGroup.get('supervisor').value['actions']==='phone'"
                  [disabled]="disableAllButtons"
                  class="text">
                  <i class="icon-file-text1"></i> Text
                </button>
              </ng-container>
            </li>
          </ul>
          <div
            class="choose-form">
            <div class="choose__list">
              <div class="choose__item">
                <span>Supervisor{{ !hasSignedAdmission || actionPOC === ActionPOCEnum.ChangePOC ? '*' : '' }}</span>
                <div class="report__select align-start">
                  <ng-select
                    (change)="selectSupervisor($event)"
                    (search)="onSearchSupervisor($event)"
                    [items]="(supervisorList$ | async)"
                    [searchable]="true"
                    appMarkInvalid
                    bindLabel="name"
                    formControlName="name"
                    placeholder="Enter Supervisor Name">
                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                      <span [innerHTML]="item.complexName || item.name"></span>
                    </ng-template>
                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-label-tmp>
                      <span [innerHTML]="item.complexName || item.name"></span>
                      <span (click)="clearSupervisor()" *ngIf="!item.disabled" class="remove-btn"></span>
                    </ng-template>
                  </ng-select>
                </div>

              </div>
              <ng-container *ngIf="dcwFormGroup.get('supervisor').value?.['actions'] === 'email';">
                <div class="choose__item">
                    <span>Supervisor
                      email{{ !hasSignedAdmission || actionPOC === ActionPOCEnum.ChangePOC ? '*' : '' }}</span>
                  <label>
                    <input
                      (input)="disableAllButtons=false"
                      appMarkInvalid
                      formControlName="email"
                      type="email"/>
                    <i (click)="setNewFields('supervisor', 'email', '')"
                       *ngIf="dcwFormGroup.get('supervisor').value['email']" class="icon-close"></i>
                  </label>
                  <span class="err-msg"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="dcwFormGroup.get('supervisor')?.value?.['actions'] === 'phone';">
                <div class="choose__item">
                    <span>Supervisor
                      phone{{ !hasSignedAdmission || actionPOC === ActionPOCEnum.ChangePOC ? '*' : '' }}</span>
                  <label>
                    <input
                      (input)="disableAllButtons=false"
                      [mask]="phoneMask"
                      appMarkInvalid
                      appMask
                      formControlName="phone"
                      placeholder="+1 (000) 000-0000"
                      type="tel"/>
                    <i (click)="setNewFields('supervisor', 'phone', '')"
                       *ngIf="dcwFormGroup.get('supervisor').value['phone']" class="icon-close"></i>
                  </label>
                  <span class="err-msg"></span>
                </div>
              </ng-container>
            </div>
            <span *ngIf="dcwFormGroup.get('supervisor').get('uid').invalid"
                  class="err-msg">{{ dcwFormGroup.get('supervisor').get('uid').getError('message') }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let dcw of dcwArray.controls; let i = index"
      >
        <div class="choose-documents-action__item">
          <div
            [formGroup]="dcw | formControl" class="choose-documents-action__buttons">
            <ul class="choose-documents-action__buttons-list">
              <li>
                <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
                  <button
                    (click)="setNewFields(i, 'actions', 'email')"
                    [class.active]="dcw.value['actions']==='email'"
                    [disabled]="disableAllButtons"
                    class="text">
                    <i class="icon-mail"></i> Email
                  </button>
                </ng-container>
              </li>
              <li>
                <ng-container *appPermission="[PermissionAdmissionEnum.CreatePOC]">
                  <button
                    (click)="setNewFields(i, 'actions', 'phone')"
                    [class.active]="dcw.value['actions']==='phone'"
                    [disabled]="disableAllButtons"
                    class="text">
                    <i class="icon-file-text1"></i> Text
                  </button>
                </ng-container>
              </li>
            </ul>
            <div
              class="choose-form">
              <div class="choose__list">
                <div class="choose__item">
                  <span>DCW name*</span>
                  <div class="report__select align-start">
                    <ng-select
                      #ngSelectDCW
                      (change)="selectDCW($event, i)"
                      (search)="onSearch($event, i, 'onlyEmployee', ngSelectDCW)"
                      [items]="(dcwsList$ | async)?.[i]"
                      [searchable]="true"
                      appMarkInvalid
                      bindLabel="name"
                      formControlName="name"
                      placeholder="Enter DCW Name">
                      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                        <span [innerHTML]="item.complexName || item.name"></span>
                      </ng-template>
                      <ng-template let-index="index" let-item="item" let-item$="item$" ng-label-tmp>
                        <span [innerHTML]="item.complexName || item.name"></span>
                        <span>{{ item.disabled }}</span>
                        <span (click)="clear(i)" *ngIf="!item.disabled" class="remove-btn"></span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <ng-container *ngIf="dcw.get('actions')?.value === 'email';">
                  <div class="choose__item">
                    <span> DCW
                      email*</span>
                    <label>
                      <input
                        (input)="disableAllButtons=false"
                        appMarkInvalid
                        formControlName="email"
                        type="email"/>
                      <i (click)="setNewFields(i, 'email', '')" *ngIf="dcw.get('email').value" class="icon-close"></i>
                    </label>
                    <span class="err-msg"></span>
                  </div>
                </ng-container>
                <ng-container *ngIf="dcw.get('actions')?.value === 'phone';">
                  <div class="choose__item">
                    <span>DCW phone*</span>
                    <label>
                      <input
                        (input)="disableAllButtons=false"
                        [mask]="phoneMask"
                        appMarkInvalid
                        appMask
                        formControlName="phone"
                        placeholder="+1 (000) 000-0000"
                        type="tel"/>
                      <i (click)="setNewFields(i, 'phone', '')" *ngIf="dcw.get('phone').value" class="icon-close"></i>
                    </label>
                    <span class="err-msg"></span>
                  </div>
                </ng-container>
              </div>
              <span *ngIf="dcw.get('uid').invalid" class="err-msg">{{ dcw.get('uid').getError('message') }}</span>
              <button
                (click)="removeDCW(i)"
                *ngIf="!dcw.get('disabled')?.value"
                class="choose-form__rm">
                <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clip-rule="evenodd"
                    d="M7.75 1.75C4.29822 1.75 1.5 4.54822 1.5 8C1.5 11.4518 4.29822 14.25 7.75 14.25C11.2018 14.25 14 11.4518 14 8C14 4.54822 11.2018 1.75 7.75 1.75ZM0 8C0 3.71979 3.46979 0.25 7.75 0.25C12.0302 0.25 15.5 3.71979 15.5 8C15.5 12.2802 12.0302 15.75 7.75 15.75C3.46979 15.75 0 12.2802 0 8ZM10.2575 5.49255C10.5504 5.78544 10.5504 6.26031 10.2575 6.55321L8.81066 8.00005L10.2609 9.45026C10.5538 9.74315 10.5538 10.218 10.2609 10.5109C9.96798 10.8038 9.4931 10.8038 9.20021 10.5109L7.75 9.06071L6.2977 10.513C6.00481 10.8059 5.52993 10.8059 5.23704 10.513C4.94415 10.2201 4.94415 9.74524 5.23704 9.45234L6.68934 8.00005L5.24041 6.55112C4.94752 6.25823 4.94752 5.78335 5.24041 5.49046C5.53331 5.19757 6.00818 5.19757 6.30107 5.49046L7.75 6.93939L9.19684 5.49255C9.48973 5.19965 9.96461 5.19965 10.2575 5.49255Z"
                    fill="#DC7070"
                    fill-rule="evenodd"/>
                </svg>
                Remove DCW
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="dcwArray.controls.length < 10" class="add-dcw">
      <button (click)="addDCW()" class="add-button">
        <i class="icon-plus"></i> Add one more DCW
      </button>
    </div>
  </div>
</div>
<div *ngIf="isPOC && next" class="button-actions">
  <button
    (click)="chooseAction('sign')"
    [disabled]="!created || disableAllButtons || getDisableSendSign() || competencyRequirementsTraining.invalid"
    class="sign-button"
    data-e2e="choose-documents-admission-sign">
    <i class="icon-signature"></i>
    Sign
  </button>
  <button
    (click)="sendDocs()"
    [disabled]="!created || disableAllButtons || isDisableSendAction || getDisableSendSign() || checkPatientData() || competencyRequirementsTraining.invalid"
    class="btn violet">
    Send
  </button>
</div>
<ng-template #loading>
  <div class="overlay">
    <img alt="loading" src="/assets/img/datatable/loading.gif"/>
  </div>
</ng-template>
